import { Injectable, computed, inject, signal } from '@angular/core';
import { ICompany, ISaveCompany } from '../@ngrx/company/company.model';
import { CompanyEffect } from '../@ngrx/company/company.effect';
import { catchError, firstValueFrom, map, of, retry, switchMap, take, takeUntil, timer } from 'rxjs';
import { CompanyStore } from '../@ngrx/company/company.reducer';
import { BaseFacadeService } from './base.facade.service';
import { EVFunctions, IFormSchema, IObjectLiteral, ISearchFormSchema, IStrictFormGroup } from 'ets-fe-ng-sdk';
import { environment } from '../../environments/environment';
import { CompaniesService } from '../../../../admin/src/app/dashboard/set-ups/companies/companies.service';
import { AsyncValidatorFn } from '@angular/forms';
import { UtilityService } from '../services/utility.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { PaymentService } from '../services/payment.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyFacadeService extends BaseFacadeService {
  protected effect = inject(CompanyEffect);

  selectAllForInputSignal = this.store.selectSignal(CompanyStore.selectors.selectAllForInput);

  companyFormField = <T extends IFormSchema = IFormSchema>(config?: Partial<IFormSchema>): T =>
    <T>{
      field: 'employerCode',
      label: 'Company',
      // hidden: !!environment.userCompanyCode,
      tableFormatter: this.fetchDescriptionByCode,
      type: 'autocomplete',
      optionsInitFunc2: this.selectAllForInput,
      valueField: 'code',
      labelType: 'ct',
      ...config,
    };

  // companyDynamicFormField = (config?: Partial<IFormSchema>) =>
  //   computed(() => this.companyFormField({ ...config, hidden: !!environment.userCompanyCodeSignal() }));
  companyDynamicFormField = <T extends IFormSchema>(config?: Partial<IFormSchema>) =>
    this.companyFormField<T>({
      hidden: !!this.authS.userCompanyCode(),
      disabled: !!this.authS.userCompanyCode(),
      ...config,
    });

  companySearchFormField = <T extends IObjectLiteral>(config?: Partial<IFormSchema<T>>) =>
    computed<ISearchFormSchema>(() => this.companyFormField(config) as ISearchFormSchema);

  constructor(
    public companyService: CompaniesService,
    public paymentService: PaymentService,
    public uS: UtilityService,
    public authS: AuthenticationService,
  ) {
    super();
    // setTimeout(() => {
    //   this.fetchAll();
    // }, 100);
  }

  validatePaidRRR: AsyncValidatorFn = (control) => {
    const val: string = control.value;
    if (!val) return of(null);
    if (val.length != this.uS.rrrLength) return of({ custom: `RRR should be ${this.uS.rrrLength} digits` });
    return timer(500).pipe(
      switchMap(() =>
        this.paymentService.checkRRRIsPaid(val).pipe(
          map((r) =>
            r.code == '00'
              ? null
              : {
                  custom: r?.message,
                },
          ),
          catchError((e) => of({ custom: e })),
        ),
      ),
    );
  };

  fetchAll() {
    this.store.dispatch(CompanyStore.actions.API.fetchAll());
  }

  fetchSingle(itemCode: string) {
    return this.ngrxPromise(CompanyStore.actions.API.fetchSingle({ code: itemCode }), {
      success: CompanyStore.actions.API.fetchSingleSuccess,
      error: CompanyStore.actions.API.fetchSingleError,
    });
  }

  fetchDescriptionByCode = (itemCode: string) => {
    return this.select(
      this.store.select(CompanyStore.selectors.selectByCode(itemCode)),
      CompanyStore.actions.API.fetchAll(),
    ).pipe(map((r) => EVFunctions.strConcatenator2(itemCode, r?.description!)));
  };

  selectAll() {
    return this.store.select(CompanyStore.selectors.selectAll);
  }

  selectAllForInput = () => {
    return this.select(
      this.store.select(CompanyStore.selectors.selectAllForInput),
      CompanyStore.actions.API.fetchAllLite(),
    );
  };

  allCDs() {
    return this.store.select(CompanyStore.selectors.selectAll);
  }
  saveCompany(company: ISaveCompany) {
    return new Promise<ICompany>((res, rej) => {
      firstValueFrom(this.effect.createSuccess).then((r) => {
        res(r.result);
      });
      firstValueFrom(this.effect.updateSuccess).then((r) => {
        res(r.result);
      });
      firstValueFrom(this.effect.createError).then((r) => {
        rej(r);
      });
      firstValueFrom(this.effect.updateError).then((r) => {
        rej(r);
      });
      !company.companyCode
        ? this.store.dispatch(CompanyStore.actions.API.create({ item: company }))
        : this.store.dispatch(CompanyStore.actions.API.update({ item: company }));
    });
  }
}
